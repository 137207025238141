function AboutPage() {
  return (
    <div>
      <div className="title-box">
        <p className="titles">O que somos?</p>
        <p className="paragraphs">
          A <strong>KPB Solutions</strong> é uma startup de tecnologia voltada
          para a inovação e excelência. Nossa especialidade está na criação de
          plataformas avançadas, dashboards interativos e soluções robustas
          baseadas em alta tecnologia. Contamos com uma equipe de
          desenvolvedores dedicados a explorar as tendências mais recentes do
          mercado. Trabalhamos com micro-serviços, Java, IA, Next.js, Kotlin e
          arquiteturas Serverless, sempre integrados à nuvem, porque no mundo
          atual, <strong>tudo está conectado!</strong>
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
