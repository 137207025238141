import './App.css';
import AboutPage from './pages/about-page';
import ContactPage from './pages/contact-page';
import DevsPage from './pages/devs-page';
import LogoPage from './pages/logo-page';
import ProjectPage from './pages/project-page';
import returnStars from './utils/stars'

function App() {
  return (
    <div className="App">
      <section className="section-logo">
        <div className="stars">{returnStars()}</div>
        <LogoPage />
      </section>
      <section className="section-presentation">
        <AboutPage />
      </section>
      <section className='section-project'>
        <div className='stars'>{returnStars()}</div>
        <ProjectPage />
      </section>
      <section className='section-devs'>
        <DevsPage />
      </section>
      <section className='section-contact'>
        <div className='stars'>{returnStars()}</div>
        <ContactPage />
      </section>
    </div>
  );
}

export default App;