function ProjectPage() {
  return (
    <div>
      <div className="title-box">
        <p className="titles white project-title">Conheça nosso projeto piloto:</p>
        <p className="paragraphs white tiny-paragraph project-paragraph">
          Um <strong>SUPER</strong> dashboard para controle de estoques, totalmente integrado com backend robusto, mensageria, monitoramento de logs e segurança avançada com OAuth e Spring Security. Oferecemos manutenção contínua, gráficos impressionantes e geração de insights de vendas utilizando Inteligência Artificial. Uma solução completa para transformar a gestão do seu negócio!
        </p>
      </div>
      <img className="image" src='project.jpg' width='780px' height='400px'/>
    </div>
  );
}

export default ProjectPage;