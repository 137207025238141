

function ContactPage() {
    return (
        <div>
            <p style={{ color: 'white'}} className="titles">Eai, gostou?</p>
            <p className="paragraphs contact-paragraph">Entre em contato conosco que solucionamos o seu problema, sempre da forma mais tecnológica e inovadora possível!</p>
            <p className="paragraphs contact-paragraph">Email: <strong>pbonifacio@kpbsolutions.net</strong></p>
        </div>
    );
}

export default ContactPage;