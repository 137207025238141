import React from 'react';

const returnStars = () => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 150; i++) {
      const x = Math.random() * 100;
      const y = Math.random() * 100;
      const delay = Math.random() * 2;

      stars.push(
        <div
          key={i}
          className="star"
          style={{
            top: `${y}%`,
            left: `${x}%`,
            animationDelay: `${delay}s`,
          }}
        ></div>
      );
    }
    return stars;
  };

  return <div className="stars">{renderStars()}</div>;
};

export default returnStars;