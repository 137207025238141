function DevsPage() {
  return (
    <div>
      <div style={{ marginTop: '4rem', justifyContent: "center", width: "100%", alignItems: 'center' }}>
        <p
          className="dev-title"
        >
          Conheça nossos desenvolvedores e fundadores:
        </p>
      </div>
      <div className="devs-div">
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <img src="bonifacio.png" height="200px" width="200px" />
            <div>
              <p className="titles dev-title">
                <strong>Pedro Bonifácio</strong>
              </p>
              <div
                style={{ display: "flex", alignItems: "center", gap: ".45rem" }}
              >
                <img src="github.png" width="40px" height="40px" />
                <a href="https://github.com/bonifacio-pedro" style={{ textDecoration: 'none', color: '#08131E'}}>
                  <p className="github-p">@bonifacio-pedro</p>  
                </a>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="title-box">
            <p className="paragraphs dev-paragraph">
              Com 2 anos de experiência na área, Pedro é reconhecido por sua
              criatividade e pensamento crítico, qualidades essenciais para
              tomar decisões estratégicas e manter a inovação como prioridade.
              Ele possui sólida expertise em Inteligência Artificial e
              Front-End, além de ser especialista em análise de dados, o que
              potencializa a criação de dashboards impactantes e insights
              valiosos. Pedro se destaca especialmente no desenvolvimento
              front-end, com foco na criação de gráficos interativos e
              integrações avançadas utilizando Next.js. Sua experiência em
              JestJS garante que todas as soluções sejam altamente testadas e
              confiáveis, consolidando sua contribuição como peça fundamental
              para o sucesso de nossos projetos.
            </p>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <img src="icampos.png" height="200px" width="200px" />
            <div>
              <p className="titles dev-title">
                <strong>Igor de Campos</strong>
              </p>
              <div
                style={{ display: "flex", alignItems: "center", gap: ".45rem" }}
              >
                <img src="github.png" width="40px" height="40px" />
                <a href="https://github.com/igorcampos-dev" style={{ textDecoration: 'none', color: '#08131E' }}>
                  <p className="github-p">@igorcampos-dev</p>
                </a>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="title-box">
            <p className="paragraphs dev-paragraph">
              Igor de Campos é um desenvolvedor Java backend com 2 anos de
              experiência na área de tecnologia. Domina Java, Spring e
              ferramentas de nuvem, tendo trabalhado com integrações em
              ambientes AWS e GCP. Possui expertise em monitoração de grandes
              aplicações monolíticas utilizando New Relic e se destacou por
              treinar desenvolvedores iniciantes, contribuindo para o
              crescimento de equipes. Com foco em soluções eficientes e
              escaláveis, Igor busca constantemente aprimorar suas habilidades e
              agregar valor aos projetos em que atua.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevsPage;
