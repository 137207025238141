


function LogoPage() {
    return (
        <div>
            <img src="logo-branco.png" height="324px" width="280px"/>
        </div>
    )
}

export default LogoPage;